@import url(https://cdn.jsdelivr.net/gh/tonsky/FiraCode@1.207/distr/fira_code.css);

.App {
  font-family: sans-serif;
  text-align: center;
  color: #f8f8f2;
}

body {
  background: #21222c;
}

code {
  font-family: "Fira Code", monospace;
}

@supports (font-variation-settings: normal) {
  code {
    font-family: "Fira Code VF", monospace;
  }
}